import Axios from 'axios';


export const searchRolesApi = ({name, id, code, description, userId, merchantId, authToken, deviceId}) => {
    if (authToken != null) {
        return Axios({
            method: 'get',
            url: window.ApiURL + 'v2/roles/search',
            params: {
                name : name,
                userId: userId,
                merchantId: merchantId,
            },
            // data: {
            //     id: id,
            //     code: code,
            //     name: name,
            //     description: description,
            // },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': authToken,
                'deviceUniqueId': deviceId
            },
        });
    };
};

export const viewRolesApi = ({id, authToken, deviceId, userId, merchantId}) => {
    if (authToken != null) {
        return Axios({
            method: 'get',
            url: window.ApiURL + `v2/roles/details?id=${id}`, 
            params: {
                userId: userId,
                merchantId: merchantId,
            },
            headers: {
                'Content-Type': 'application/x-www-form-urleusencoded',
                'authToken': authToken,
                'deviceUniqueId': deviceId
            },
        });
    }
    ;
};
export const createRolesApi = ({authToken, data, userId, merchantId, deviceId}) => {
    if (authToken != null) {

        return Axios({
            method: 'post',
            url: window.ApiURL + `v2/roles/create`,
            params: {
                userId: userId,
                merchantId: merchantId,
            },
            data: {
                code: data.code,
                name: data.name,
                description: data.description,
                privileges: data.privileges,
            },
            headers: {
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId': deviceId
            },
        });
    }
    ;
};

export const updateRolesApi = ({authToken, id, data, userId, merchantId, deviceId}) => {
    if (authToken != null) {
        return Axios({
            method: 'put',
            url: window.ApiURL + `v2/roles/update?id=${id}`,
            params: {
                roleId: id,
                userId: userId,
                merchantId: merchantId,
            },

            data: {
                id: id,
                code: data.code,
                name: data.name,
                description: data.description,
                privileges: data.privileges,
            },
            headers: {
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId': deviceId
            },
        });
    }
    ;
};

export const deleteRolesApi = ({authToken, deviceId, id, userId, merchantId}) => {
    if (authToken != null) {
        return Axios({
            method: 'delete',
            url: window.ApiURL + `v2/roles/delete?id=${id}`,
            params: {
                roleId: id,
                userId: userId,
                merchantId: merchantId,
            },
            data: {},
            headers:{
                'Content-Type': 'application/x-www-form-urleusencoded',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            },
        });
    };
};

export const getListPrivilegesApi = ({authToken, deviceUniqueId, userId, merchantId, size, page}) => {
    if (authToken != null) {
        return Axios({
            method: 'post',
            url: window.ApiURL + 'v2/privileges/all-selected',
            data: {
                data: []
            },
            params: {
                'userId': userId,
                'merchantId': merchantId,
                'size': size === undefined || size === null ? 20 : size,
                'page': page === undefined || page === null ? 0 : page
            },
            headers: {
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId': deviceUniqueId,
            }
        });
    }
    ;
};
