import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    SEARCH_AUDIT_TRAIL,
    SEARCH_AUDIT_TRAIL_SUCCESS,
    SEARCH_AUDIT_TRAIL_FAILED,
    VIEW_AUDIT_TRAIL,
    VIEW_AUDIT_TRAIL_SUCCESS,
    VIEW_AUDIT_TRAIL_FAILED,
    BACK_TO_LOGIN,
    GET_LIST_MODULE_NAME,
    GET_LIST_MODULE_NAME_SUCCESS,
    GET_LIST_MODULE_NAME_FAILED
} from "constants/ActionTypes";
import {
    searchAuditTrailApi,
    viewAuditTrailApi,
    listModuleNameApi
} from "../../appRedux/api/AuditTrail";


function* fetchSearchAuditTrail({payload}) {
    if(payload != null){
        try {
            const searchAuditTrailData = yield call(searchAuditTrailApi, payload);
            if (searchAuditTrailData.data.abstractResponse.responseStatus === 'INQ000') {
                yield put({type: SEARCH_AUDIT_TRAIL_SUCCESS, payload: searchAuditTrailData.data});
            } else{          
                yield put({type: SEARCH_AUDIT_TRAIL_FAILED, payload: searchAuditTrailData.data.abstractResponse.responseMessage});
            }
        } catch (error) {
            if(error.response !== undefined) {
                if (error.response.data.abstractResponse === null) {
                    yield put({
                        type: SEARCH_AUDIT_TRAIL_FAILED,
                        payload: "Sorry, this feature is not accessible at this time"
                    });
                } else if (error.response.data.abstractResponse.responseStatus === 'AUTH001') {
                    yield put({type: BACK_TO_LOGIN, payload: error.response.data.abstractResponse.responseMessage});
                } else {
                    yield put({
                        type: SEARCH_AUDIT_TRAIL_FAILED,
                        payload: error.response.data.abstractResponse.responseMessage
                    });
                }
            }
        }
    }
}

function* fetchAuditTrail({payload}) {
    if(payload != null){
        try {
            let viewAuditTrailData = yield call(viewAuditTrailApi, payload);
            if (viewAuditTrailData.data.abstractResponse.responseStatus === 'INQ000') {
                yield put({type: VIEW_AUDIT_TRAIL_SUCCESS, payload: viewAuditTrailData.data.member});
            } else {
                yield put({type: VIEW_AUDIT_TRAIL_FAILED, payload: viewAuditTrailData.data.abstractResponse.responseMessage});
            }
        } catch (error) {
            if(error.response !== undefined) {
                if (error.response.data.abstractResponse === null) {
                    yield put({
                        type: VIEW_AUDIT_TRAIL_FAILED,
                        payload: "Sorry, this feature is not accessible at this time"
                    });
                } else if (error.response.data.abstractResponse.responseStatus === 'AUTH001') {
                    yield put({type: BACK_TO_LOGIN, payload: error.response.data.abstractResponse.responseMessage});
                } else {
                    yield put({
                        type: VIEW_AUDIT_TRAIL_FAILED,
                        payload: error.response.data.abstractResponse.responseMessage
                    });
                }
            }else{
                yield put({
                    type: VIEW_AUDIT_TRAIL_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}

function* fetchListModuleName({payload}) {
    if(payload != null){
        try {
            const response = yield call(listModuleNameApi, payload);
            if (response.data.abstractResponse.responseStatus === 'INQ000') {
                yield put({type: GET_LIST_MODULE_NAME_SUCCESS, payload: response.data});
            } else{
                yield put({type: GET_LIST_MODULE_NAME_FAILED, payload: response.data.abstractResponses.responseMessage});
            }
        } catch (error) {
            if(error.response !== undefined) {
                if (error.response.data.abstractResponse === null) {
                    yield put({
                        type: GET_LIST_MODULE_NAME_FAILED,
                        payload: "Sorry, this feature is not accessible at this time"
                    });
                } else if (error.response.data.abstractResponse.responseStatus === 'AUTH001') {
                    yield put({type: BACK_TO_LOGIN, payload: error.response.data.abstractResponse.responseMessage});
                } else {
                    yield put({
                        type: GET_LIST_MODULE_NAME_FAILED,
                        payload: error.response.data.abstractResponse.responseMessage
                    });
                }
            }else{
                yield put({
                    type: GET_LIST_MODULE_NAME_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}


export function* searchAuditTrail() {
    yield takeEvery(SEARCH_AUDIT_TRAIL, fetchSearchAuditTrail);
}

export function* viewAuditTrail(){
    yield takeEvery(VIEW_AUDIT_TRAIL, fetchAuditTrail);
}

export function* getListModuleName() {
    yield takeEvery(GET_LIST_MODULE_NAME, fetchListModuleName);
}


// yield all
export default function* rootSaga() {
    yield all([
        fork(searchAuditTrail),
        fork(viewAuditTrail),
        fork(getListModuleName)
    ]);
}
