import {
    VIEW_CATEGORY,
    VIEW_CATEGORY_SUCCESS,
    VIEW_CATEGORY_FAILED,
    FILTER_SEARCH_CATEGORY,
    CLEAR_FILTER_SEARCH_CATEGORY,
    RESET_STATUS,
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    categoryData : [],
    recordInfo: {
        totalRecords: 0,
        page : 0,
        nrecords : 0
    },
    filterAndSort : {
        pagination : null,
        filters : null,
        sorter : null,
        search : null
    },
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FILTER_SEARCH_CATEGORY: {
            return {
              ...state,
              filterAndSort: action.payload
            };
          }

          case CLEAR_FILTER_SEARCH_CATEGORY: {
            return {
              ...state,
              filterAndSort: {
                pagination: null,
                filters: null,
                sorter: null,
                search: null
              }
            };
          }

        case VIEW_CATEGORY: {
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: '',
            }
        }

        case RESET_STATUS : {
            return {
                ...state,
            }
        }

        // Response

        case VIEW_CATEGORY_SUCCESS: {
            return {
                ...state,
                loader: false,
                categoryData: action.payload
            }

        }

        case VIEW_CATEGORY_FAILED: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload,
                showMessage: true
            }
        }

        default:
            return state;
    }
}
